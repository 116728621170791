<!-- 花名册列表 -->
<template>
<div class="table">
  <el-table
    ref="multipleTable"
    :data="tableData"
    :highlight-current-row="true"
    tooltip-effect="dark"
    style="width:100%;color:#858585;"
    @selection-change="selectRow"
    :header-cell-style="tableHeaderCellStyle">
    <el-table-column
     type="selection"
     fill="#00CCCC"
     align="center"
     fixed="left">
    </el-table-column>
    <!-- 表头开始 -->
    <el-table-column
      prop="name"
      label="姓名"
      align="center">
    </el-table-column>
    <el-table-column
      label="年级"
      align="center">
      <template slot-scope="scope">{{ scope.row.studentGrade }}年级</template>
    </el-table-column>
    <el-table-column
      prop="studentGradeOrClass"
      label="班级"
      align="center">
      <template slot-scope="scope">（{{ scope.row.studentClass }}）班</template>
    </el-table-column>
     <el-table-column
      prop="cardId"
      label="身份证号"
      align="center">
    </el-table-column>
    
  </el-table>

</div>
</template>

<script>
import {getClassStudentList} from '@/api/roster.js'
  export default {
    data() {
      return {
        queryInfo:null,
        tableData: [],//表格数据
        multipleSelection:null,// tableCheckboxValue
        // 分页相关
        currentPage:1,// 当前页面,默认1
        tatalPage:0,//总页数,为加载时先为0，页面首次加载后去请求即可
        count:0,//总的条数记录
        onlyPage:10,// 当前页面可以展示多少条数据,默认10 

      }
    },
      created(){
        if(this.$route.query){
          
            this.queryInfo = this.$route.query;
           this.getData(1,40,this.$route.query.orgId,this.$route.query.grade,this.$route.query.Class);
        }
    },
    //监听勾选值
    watch:{
        multipleSelection:{
            handler(fd,ol){
                console.log("监听",fd);
                if(fd.length==0){
                    this.$parent.downloadOneStatus= false;
                }
                if(fd.length!=0){
                    this.$parent.downloadOneStatus= true;
                }
            },
            deep:true,
            immediate:true
        }
    },
    methods: {
      //表头样式回调
      tableHeaderCellStyle(){
          return "color:#fff;background-color:#00CCCC";
        },
      // getData
      getData(currentPage,onlyPage,orgId,grade,Class){
              const rLoading = this.openLoading();// 开始loading
              getClassStudentList({
              orgId:orgId,
              studentClass: Class,
              studentGrade: grade,
              currentPage: currentPage,
              pageSize: onlyPage
            }).then((res)=>{
              if(res.data.status == 200){
                 this.tableData = res.data.data;
                 rLoading.close() ;// 关闭loading
              }else{
                this.$message.error(res.data.message);
              }
            }).catch(res=>{
               this.$message.error(res);
            })
      },
      // 复选框选项
      selectRow(val) {
        //console.log("当前选框为",val)
        this.multipleSelection = val;
        //console.log("mutible",this.multipleSelection);
      },
    },
  }
</script>
<style scoped>
.table{
    margin-top: .1563rem;
   
}
.el-button{
    color: #00A3A3 !important;
}
.action{
    display: flex;
    flex-direction: column;
}
.actionP{
  cursor: pointer;
  color: #00CCCC;
}
</style>